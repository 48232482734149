@import '../../../variables.scss';

.scoreSummary {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;

  font-size: $small;
  padding: $defaultPadding;
  box-sizing: border-box;

  .composite {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    padding: 0 10px;
  }
  .sections {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .subjectScore {
    box-sizing: border-box;
    width: 120px;
    margin: 3px;
    padding-top: 10px;
  }
}

@media only screen and (max-width:$viewTablet) {
  .scoreSummary {
    flex-direction: column;
    justify-content: center;
    .composite {
      width: 100%;
      height: 150px;
    }
  }
}