@import '../../../variables.scss';

.selectOption {
    display: flex;
    flex-direction: column;
    padding: 5px 12px;
    box-sizing: border-box;
    margin: 2px;
}

.selectOption:hover {
    color: white;
    background: $primary;
    // border: 1px solid $primary;
    // background: $highlightColor;
    // border-radius: $borderRadius;
}