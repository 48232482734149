@import '../../../variables.scss';

.practiceTestEvents {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  font-size: $small;

  .filterContainer { 
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .filterOption {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 5px;
    align-items: center;
    .label {
      margin-right: 5px;
    }
  }
  .table {
    table-layout: auto;
    border: 1px solid $grayDark;
    border-right: none;
    border-bottom: none;
    border-spacing: 0;

    th {
      border-bottom: 1px solid $grayDark;
      border-right: 1px solid $grayDark;
      padding: 5px;
    }
    
    td {
      border-right: 1px solid $grayDark;
      border-bottom: 1px solid $grayDark;
      padding: 5px;
    }
    td:last-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-width: 100px;;
    }
    
    .button {
      padding: 5px 2px;
    }
    .exportButton {
      box-sizing: border-box;
      background: $buttonColorA2;
      color: $buttonColorA1;
      border: 1px solid $buttonColorA1;
      border-radius: $borderRadius;

      font-weight: $normal;
      outline: none;
      text-decoration: none;
      font-size: $extraSmall;
      padding: 3px 5px;
      text-transform: lowercase;
      
      &:hover {
        cursor: pointer;
        background: $buttonColorA1;
        color: $buttonColorA2;
        border-color: $buttonColorA2;
      }
    }
    .tableHeader {
      text-align: left;
      text-transform: uppercase;
      // border-bottom: 1px solid $color1;
    }

  }
}