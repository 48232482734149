@import '../../variables.scss';

$color1: $primary;

.reportTable {
  border: 1px solid $gray;
  border-spacing: 0;
  font-size: $extraSmall;
  background: white;
  td {
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;
    padding: 0 5px;
  }
  th {
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;
    padding: 0 5px;
  }
  .tableHeader {
    // background: $color1;
    // color: white;
    text-align: left;
    text-transform: uppercase;
    font-weight: $thin;
  }
  .tableBody {
    border: 1px solid $gray;
  }
  // .tableRow:nth-child(odd) {
  //   background-color: white;
  // }
  // .tableRow:nth-child(even) {
  //   background-color: lighten($primaryExtraLight, 5%);
  // }
}