@import '../../variables.scss';

.studentDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: $box-shadow;
  padding: $defaultPadding;
  width: 100%;
  .titleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $primary;
    
    width: 100%;
    font-size: $smallHeader;
    font-weight: $normal;
    margin-bottom: 10px;
  }
  .backLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primary;
    font-size: $small;
    border: none;
    background: none;
    img {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
  .backLink:hover {
    cursor: pointer;
    color: $highlightColor;
    text-decoration: underline;
  }

  .subTitle {
    color: $primary;
    font-size: $smallHeader;
    font-variant: small-caps;
    margin: 5px;
  }
  .metrics {
    width: 100%;
    // border: 1px solid $grayLight;  
  }
  .scores {
    // border: 1px solid $grayLight;
    width: 100%;
  }
  .activity {
    // border: 1px solid $grayLight;
    width: 100%;
  }
}

