@import '../../variables.scss';

.dashboardCardQuestions {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 50px;

  .title {
    font-size: $subHeader;
  }
  .footnote {
    font-size: $tinyText;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .value {
    // text-align: right;
    font-size: 36px;
    color: $primary;
    font-weight: bold;
    margin-right: 2px;
  }
  td {
    text-align: center;
    padding: 0 10px;
  }
  // tr:first-child { background: yellow; }  
  .separator {
    border-bottom: 1px solid $primary;
    width: 100%;
    margin: 10px auto;
  }
}