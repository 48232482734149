@import '../../variables.scss';

$subtextSize: 16px;
$subtextWeight: $thin;

.studentScoreSummary {
  display: flex;
  flex-direction: row;
  width: 100%;
  .scoreCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin: 2px;
    border-radius: $borderRadius;
    background: white;
  }
  .practiceTests {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 2px;
    border-radius: $borderRadius;
    background: white;

    .practiceTestTable {
      padding: 20px;
      font-size: $small;

      td {
        padding: 0 5px;
      }
      td:first-child { text-align: right; }
      td:nth-child(4) { text-align: right; }
    }

    .title {
      color: $primary;
      font-weight: $normal;
      text-align: center;
      width: 100%;
      font-size: $standard;
    }
  }
  .testLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primary;
    font-size: $small;
    border: none;
    background: none;
  }
  .testLink:hover {
    cursor: pointer;
    color: $highlightColor;
    text-decoration: underline;
  }
  
}
@media only screen and (max-width:$viewTablet) {
  .studentScoreSummary {
    flex-direction: column;
  }
}