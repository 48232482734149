@import "../../variables.scss";

.button {
  box-sizing: border-box;
  background: $buttonColorA1;
  color: $buttonColorA2;
  border: 1px solid $buttonColorA2;
  border-radius: $borderRadius;
  font-size: $small;
  font-weight: $normal;
  outline: none;
  padding: 8px 12px;
  line-height: 1.4em;
  text-transform: uppercase; 
  &:hover {
    cursor: pointer;
    background: $buttonColorA2;
    color: $buttonColorA1;
    border-color:  $buttonColorA1;
  }
  &:disabled {
    cursor: default;
    background: $grayDark;
    color: $gray;
    border-width: 1px;
  }
}
.button.cta {
  background: $buttonColorA2;
  color: $buttonColorA1;
  border-color:  $buttonColorA1;

  &:hover {
    background: $buttonColorA1;
    color: $buttonColorA2;
    border-color:  $buttonColorA2;
  }
  &:disabled {
    cursor: default;
    background: $grayDark;
    color: $gray;
    border-width: 1px;
    border-color: $gray;
  }
}

button.small {
  font-size: $extraSmall;
  outline: none;
  padding: 3px 5px;
  text-transform: lowercase;
}

button.link {
  box-sizing: border-box;
  font-size: $small;
  font-weight: $normal;
  outline: none;
  padding: 0px;
  background: none;
  border:none;
  color: $buttonColorA1;
  text-transform: lowercase;
  text-decoration: underline;
  
  &:hover {
    background-color: none;
    border: none;
    cursor: pointer;
    color: $highlightColor;
  }
}

