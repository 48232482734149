@import '../../../variables.scss';

.studentReport {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  .pageTitle {
    font-size: $header;
  }
  .reportLoading {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .reportLoadError {
    display: flex;
    flex-direction: column;
  }
  .reportContainer {
    display: flex;
    flex-direction: column;
  }
  .reportScrollView {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
    background: white;
  }
  .reportButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    img {
      height: 20px;
      width: 20px;
    }
    img:hover {
      color: $highlightColor;
    }
  }
  .exportButton {
    color: $primary;
    text-decoration: none;
  }
  .exportButton:hover {
    color: $highlightColor;
  }
  .parametersMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px 10px;
    border-bottom: 1px solid white;
    .parameters {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      font-size: $subHeader;
      margin-right: 50px;
    }
  }

  .practiceTestParam {
    box-sizing: border-box;
    font-size: $standard;
    font-weight: $light;
    outline: none;
    padding: 0px;
    background: none;
    border: none;
    color: $buttonColorA1;
    margin-bottom: 3px;
    // text-decoration: underline;
    &:hover {
      background-color: none;
      border: none;
      cursor: pointer;
      color: $highlightColor;
      text-decoration: underline;
    }
  }  
}