@import '../../variables.scss';

.studentActivityLog {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $defaultPadding;
  // border: 2px solid $primary;

  margin: 2px;
  border-radius: $borderRadius;
  background: white;
 
  @media only screen and (max-width:$viewPhone) {
    .logHeader {
      display: none;
    }
  }
}

