@import 'variables.scss';

.appMain {
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $backgroundLight;
    background-image: $backgroundLightImage;
    color: $color;
    // font-family: $primaryFont;
    font-weight: 100;
    font-size: $standard;
}
  