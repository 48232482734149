@import '../../variables.scss';

$subtextSize: 14px;
$subtextWeight: $thin;
$metricSizeBig: 54px;
$metricSizeMed: 33px;

.studentMetricsBar {
   
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  box-sizing: border-box;

  .metric {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: $primary;
    margin: 2px;
    border-radius: $borderRadius;
    background: white;
    box-sizing: border-box;
    width: 175px;
    height: 180px;
    margin: 5px;
    padding: 20px 5px 5px 5px;
  }
  .title {
    text-align: center;
    font-size: $small;
    font-weight: $semibold;
    text-transform: uppercase;
  }
  .footnote {
    font-size: $subtextSize;
    font-weight: $thin;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%; 
  }
  .noData {
    font-size: $standard;
  }
  .singleMetic {
    font-size: $metricSizeBig;
    font-weight: $semibold;
  }
  .metricPair {
    display: flex;
    flex-direction: column;
  }
  .questionMetric {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $metricSizeMed;
    font-weight: $semibold;
    span {
      font-size: $subtextSize;
      font-weight: $subtextWeight;
    }
  }
  .timeMetric {
    .timeValue {
      font-size: $metricSizeMed;
      font-weight: $semibold;
    }
    .timeUnit {
      font-size: 18px;
      font-weight: $semibold;
    }
  }
  .scoresContent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    margin-top: 5px;
    .labels {
      box-sizing: border-box;
      font-size: $subtextSize;
      display: flex;
      flex-direction: column;
      width: 60px;
      text-align: left;
      justify-content: space-around;
      height: 100%;
      margin-left: 5px;

      // .label {
      //   border: 1px solid;
      // }
      // background: pink;
    }
    
    .scores {
      // background: lightgreen;
      .score {
        flex: 1;
      }

      box-sizing: border-box;
      font-size: $metricSizeMed;
      height: 100%;
      font-weight: $semibold;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-evenly;
      text-align: right;
    }
  }
}