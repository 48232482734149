@import '../../variables.scss';

.dashboardCardTimeOnSite {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 50px;

  .title {
    font-size: $subHeader;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .metric {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
  }
  .metricValue {
    align-self: right;
    .number {
      font-size: 36px;
      color: $primary;
      font-weight: bold;
      margin-right: 2px;
    }
    .timeUnit {
      font-size: $small;
      font-weight: $normal;
    }
  }
  .metricLabel {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 50px;  
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid $primary;
    font-size: $small;
  }

}