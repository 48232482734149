@import '../../variables.scss';

.reportHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  // border: 1px solid $primaryExtraLight;
  box-sizing: border-box;

  padding: 10px;
}
  