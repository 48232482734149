@import '../../../variables.scss';

.scoreCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .chartContainer {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 120px;
    height: 120px;
  }
  .score {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    font-size: calc(16px + 1vmin);
    font-weight: $light;
    .scoreOutOf {
      font-size: $tinyText;
    }
  }
 
  .chartTitle {
    box-sizing: border-box;
    display: flex;
    font-size: $standard;
  }
}

:export { completed: $primary; }
:export { remaining: white; }
