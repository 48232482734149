@import '../../variables.scss';

.verifyUser {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	.mainContainer {
		box-sizing: border-box;
		padding: 10vh 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid white;
		border-radius: $borderRadius;
		@media screen and (max-width:$viewPhone) {
			flex: 1;
			width: 100%;
			border: none;
		}
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		button {
			margin: 0 15px;
		}
	}
	.info {
		font-size: $smallHeader;
		margin-bottom: 1vh;
  }
  .textInput {
    margin-bottom: 1vh;
  }
	.helpInfo {
		font-size: $standard;
		margin-top: 3vh;
	}
	.bottom {
		width: 100%;
	}
	.formContainer {
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		width: 100%;
    margin-top: 5vh;
    form {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		margin: 1vh 0;
		width: 100%;	
	}
	a {
		color: $highlightColor;
	}
}
.submitButton {
  box-sizing: border-box;
  background: $buttonColorA1;
  color: $buttonColorA2;
  border: 1px solid $buttonColorA2;
  border-radius: $borderRadius;
  font-size: $small;
  font-weight: $normal;
  outline: none;
  padding: 8px 12px;
  line-height: 1.4em;
  text-transform: uppercase; 
  &:hover {
    cursor: pointer;
    background: $buttonColorA2;
    color: $buttonColorA1;
    border-color:  $buttonColorA1;
  }
  &:disabled {
    cursor: default;
    background: $grayDark;
    color: $gray;
    border-width: 1px;
  }
}