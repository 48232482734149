@import '../../variables.scss';

.studentList {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: $defaultPadding;
  font-size: $small;  
  thead {
    text-align: left;
  }
  .studentItem {
    box-sizing: border-box;
    padding: $defaultPadding;
    font-size: $small;  
    tr {
      text-align: left;
    }
    td {
      padding: 2px;
    }
  }
  .studentItem:hover {
    color: $highlightColor;
    cursor: pointer;
  }

}