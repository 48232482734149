@import '../../variables.scss';

.verifyUser {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	.mainContainer {
		box-sizing: border-box;
		padding: 10vh 5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid white;
		border-radius: $borderRadius;
		@media screen and (max-width:$viewPhone) {
			flex: 1;
			width: 100%;
			border: none;
		}
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		button {
			margin: 0 15px;
		}
	}
	.info {
		font-size: $smallHeader;
		margin-bottom: 1vh;
	}
	.helpInfo {
		font-size: $standard;
		margin-top: 3vh;
	}
	.bottom {
		width: 100%;
	}
	.formContainer {
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		margin-top: 5vh;
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		margin: 1vh 0;
		width: 100%;	
	}
	a {
		color: $highlightColor;
	}
}