@import '../../../variables.scss';

$cellBorder: 1px solid $grayLight;
$padding: 1px 2px;

.questionLogItem {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid $grayDark;
  // padding: 3px 10px;
  font-size: $small;
  width: 100%;
  text-align: left;
  
  .questionDate {
    width: 75px;
    border: $cellBorder;
    padding: $padding;
  }
  .subject {
    width: 150px;
    border: $cellBorder;
    padding: $padding;
  }
  .questionCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: $cellBorder;
    padding: $padding;
    flex: 1;
    .questionBody {
      border: 1px solid red;
      flex:1;
    }
    .gotoQuestionCell {
      width: 20px;
      img { 
        width: 20px;
      }
    }
  }
 
  .reviewCell {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: $cellBorder;
    padding: $padding;
    flex: 1;

    .reviewedDate {
      font-size: $extraSmall;
      font-weight: $semibold;
    }
    .reviewFlag {
      width: 20px;
      height: 20px;
      img { 
        width: 20px;
      }
    }
    .missingReviewNote {
      align-items: center;
      width: 100%;
      text-align: center;
      font-size: $extraSmall;
      font-style: italic;
    }
  }
 

  @media only screen and (max-width:$viewPhone) {
    border-bottom: 5px dotted $grayDark;
    flex-direction: column;
    .item {
      background-color: white;
      flex-direction: row;
      width: 100%;
      .itemLabel {
        display: flex;
        width: 20%;
      }
      .itemValue {
        width: 75%;
      }
    }
    .questionBody {
      background-color: white;
      flex-direction: column;
      font-size: $standard;
      width: 100%;
      .itemLabel {
        display:none;
      }
      .itemValue {
        width: 100%;
      }

    }
    .questionDate {
      text-align: left;
    }
    .reviewDate {
      text-align: left;
    }
    .katex {
      white-space: normal !important;
    }

  }
}
