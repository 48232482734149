@import '../../variables.scss';

.programSelector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $primary;
  box-sizing: border-box;
  font-weight: $light;
  font-size: $standard;
  .selectedDisplay {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: $subHeader;
  }
  .selectedOptionText{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .showMenuButton {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background: none;
    border: none;
    margin-left: 2px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .showMenuButton:hover {
    cursor: pointer;
  }
  .dropDownContainer {
    position: relative;
    display: inline-block;
  }
  
  .dropDownList {
    // opacity: 1;
    // transition: all .5s ease;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    box-shadow: $box-shadow;
    position: absolute;
    z-index: 1;
    background: white;
    cursor: pointer;
    border-color: $primary;
    padding: $defaultPadding;
  }
}