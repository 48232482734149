@import '../../../variables.scss';

$cellBorder: 1px solid $grayLight;
$padding: 1px 2px;

.questionLogHeader {
  border-bottom: 2px solid $primary;
  box-sizing: border-box;
  color: $primary;
  font-weight: $normal;
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  .questionDate {
    width: 75px;
    border: $cellBorder;
    padding: $padding;
  }
  .subject {
    width: 150px;
    border: $cellBorder;
    padding: $padding;
  }
  .question {
    flex: 1;
    border: $cellBorder;
    padding: $padding;
  }
  .review {
    flex: 1;
    border: $cellBorder;
    padding: $padding;
  }
}
