@import '../../../variables.scss';

.practiceTestHistoryChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  font-size: 12px;
  font-weight: $normal;

  .chartContainer {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 150px;
    min-height: 150px;
  }
  .chartTitle {
    box-sizing: border-box;
    display: flex;
    font-size: $subHeader;
  }

}


:export { completed: $primary; }
:export { remaining: white; }
