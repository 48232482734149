@import '../../variables.scss';

.homeLayoutContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
 
  .leftSideNav {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-right: 1px solid white;
    height: 100%;
    width: 150px;
  }
  .titleBar {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    padding-bottom: $defaultPadding;
    font-size: $title;
  }
  .topNav {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    padding-bottom: $defaultPadding;
  }
  .rightSideContent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    overflow: auto;
  }
}

@media print {
  .homeLayoutContainer {
    .leftSideNav {
      display: none;
    }
    .rightSideContent {
      overflow: initial;
    }
  }
}