@import '../../variables.scss';

.practiceTestHome {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  // width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: $box-shadow;
    background: white;
    margin: 20px 5px;
    padding: $defaultPadding;
  }
  .scheduleHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  
}