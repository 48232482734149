@import '../../variables.scss';

.sideNavBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: $defaultPadding;
  font-size: $small;  
  max-width: 200px;
  overflow-x: hide;
  .navTop {
    box-sizing: border-box;
    padding: $defaultPadding;
  }
  .navBottom {
    box-sizing: border-box;
    border-top: 1px solid white;
    padding: $defaultPadding;
  }
  .logo {
    margin: 30px 0;
  }
}