@import '../../variables.scss';

.dashboardCardReview {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 50px;

  .title {
    font-size: $subHeader;
  }
  .footnote {
    font-size: $tinyText;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  .metric {

    .number {
      font-size: $subHeader;
      font-weight: normal;
    }
    .text {
      font-size: $small;
    }
  }
  
  .separator {
    border-bottom: 1px solid $primary;
  }
}