@import '../../../variables.scss';

.practiceTestEventAddEdit {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  padding: 20px 30px;

  .form {
    margin-bottom: 10px;
    max-width: 500px;
    // border-bottom: 1px solid $primary;
  }
  .title {
    font-size: $header;
    color: $primary;
    font-weight: $normal;
    margin-bottom: $defaultPadding;
  }

  .label {
    box-sizing: border-box;
    margin-right: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $defaultPadding;
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: $defaultPadding 0;
    
    Button {
      margin-right: 5px;
    }
    
  }

}