@import '../../../variables.scss';

.practiceTestEventDetails {
  display: flex;
  flex-direction: column;
  background: white;
  // box-shadow: $box-shadow;
  box-sizing: border-box;
  // padding: 20px 30px;

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $standard;
    border: 1px solid $grayLight;
    padding: $defaultPadding;
    span {
      // color: $primary;
      font-weight: $normal;
    }
    margin-bottom: $defaultPadding;

    .textBlock {
     display: flex;
     flex-direction: column;
    }

    .exportButtons {
      display: flex;
      flex-direction: row;
      img { width: 40px;}
    }
    tr {
      border: 1px solid $grayLight;
    }

  }

  .studentList {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: $defaultPadding;
    font-size: $small;  
    tr {
      text-align: left;
    }
  }

  .exportButton {
    color: $primary;
    text-decoration: none;
  }
  .exportButton:hover {
    color: $highlightColor;
  }
}