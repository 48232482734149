@import '../../variables.scss';

.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  .label{
    box-sizing: border-box;
    margin-right: 10px;
  }
  select {
    border: thin solid gray;
    font-size: $small;
    font-weight: $thin !important;
    padding: 3px;
  }
  .showMenuButton {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background: none;
    border: none;
    img {
      width: 30px;
      height: 30px;
    }
  }
}