@import '../../../variables.scss';

.sideNavBarItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 10px 0;

  box-sizing: border-box;
  border-bottom: 1px solid white;
  text-decoration: none;
  color: $color;
  text-align: right;

}
.selectedItem {
  color: $highlightColor;
}
.sideNavBarItem:hover {
  color: $highlightColor;
  cursor: pointer;
}