@import '../../variables.scss';

.dashboard {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;

  .cards {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    flex-wrap: wrap;

    padding-top: 20px;
    overflow-y: auto;
  }
}