// Colors
$brandColor: #3B3561;
$brandColor2: #a99fd3;

// $secondary: #3B3561;
// $secondaryLight: #a99fd3;
// $secondaryExtraLight: #d1c6fc;
// $secondaryDark: darken($secondary, 30%);
// $secondaryExtraDark: darken($secondary, 50%);

// $primary: #134f8d;
// $primaryLigh: #5ba0d0;
// $primaryDark: #2c3e50;
// $primaryExtraLight: #ceddef;

$primary: #3B3561;
$primaryLight: #a99fd3;
$primaryExtraLight: #d1c6fc;
$primaryDark: darken($primary, 30%);
$primaryExtraDark: darken($primary, 50%);

$secondary: #134f8d;
$secondaryLight: #5ba0d0;
$secondaryDark: #2c3e50;
$secondaryExtraLight: #ceddef;


$gray: #cecece;
$grayDark: #959ea7;
$grayExtraDark: #1d1f21;
$grayLight: #e9ebed;
$grayExtraLight: #f6f6f6;
$errorColor: #ff0033;
$highlightColor:#F5AB00;;

$math:#963484;
$ela: #134f8d;
$science: #3B3561;
$scienceSmallText: #d1c6fc;
$scienceText: #a99fd3;
$reading: #f58b1e;

$mathLight: lighten($math, 20%);
$elaLight: lighten($ela, 30%);
$scienceLight: lighten($science, 30%);
$readingLight: lighten($reading, 30%);
 
$color: black;
$headerColor: black;
$headerBackground: #ceddef;
$backgroundLight: #ceddef;
$backgroundLightImage: linear-gradient(lighten(#ceddef, 10%), lighten(#ceddef, 5%));
$backgroundDark: $headerColor;
$backgroundImage: linear-gradient(lighten($grayExtraDark, 20%), $grayExtraDark);

$defaultPadding: 2vmin;

$buttonColorA1: $brandColor;
$buttonColorA2: white;
$buttonColorB1: $primary;
$buttonColorB2: white;
$buttonColorA1Highlight: #F9F871;

$timerDefault: #00d3da;
$timerFirstWarning: #F9F871;
$timerSecondWarning: #f58b1e;
$timerFinalWarning: #ff0033; 
// #F3615E 
$maxContentWidth: 800px;
$contentWidth: 500px;

// Fonts
$primaryFont: 'Montserrat';  //Montserrat
$headerFont: 'Montserrat';
$fontColor: white;

$headerHeight: 56px;

// Font Sizes 14–18px
$tinyText: 12px;
$extraSmall: 14px;
$small: 16px;
$standard: 18px;
// 28–34px
$smallHeader: 26px;
$subHeader: 30px; 
$header: 32px;
$title: 34px;

// Font Weights
$thin: 100;
$light: 300;
$normal: 400;
$semibold: 600;
$bold: 700;
$extrabold: 800;

// miscellaneous
$borderRadius: 5px;
$box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
$maxWidth: 800px;

// breakpoints
$viewPhone: 600px;
$viewTablet: 900px;
$viewDesktop: 1200px;

$breakWidth: 900px;