@import '../../variables.scss';

.reportLink {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 50px;

  .reportInfo {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .title {
    font-size: $subHeader;
  }
}
.reportLink:hover {
  cursor: pointer;
  .title {
    color: $highlightColor;
    text-decoration: underline;
  }
}
