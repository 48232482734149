@import "../../../variables.scss";

.programAdmin {

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: $box-shadow;
    background: white;
    margin: 20px 5px;
    padding: $defaultPadding;
  }

  .titleRow{
    display: flex;
    flex-direction: row;
    font-size: $header;
    align-items: flex-end;
    .title {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    flex-wrap: wrap;

    Button {
      margin: 0 0 0 15px;
    }
  }

  table{
    border-collapse:collapse;
    border:1px solid $primary;

    th {
      background-color: $secondaryExtraLight;
      border: 1px solid $primary;
      padding: 3px;
    }
  }
    
  table td{
    border: 1px solid $primary;
    text-overflow: ellipsis;
    padding: 3px;
  }

  .uploadContainer {
    button {
      width: 200px;
      margin-top: 5px;
    }
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 100%;
    font-size: $standard;
  }

}