@import '../../../variables.scss';

.growthReport {

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  .pageTitle {
    font-size: $header;
  }
  .reportLoading {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .reportLoadError {
    display: flex;
    flex-direction: column;
  }
  .reportContainer {
    display: flex;
    flex-direction: column;
  
  }
  .reportScrollView {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
    background: white;
  }
  .reportButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
    img:hover {
      color: $highlightColor;
    }
  }
  .exportButton {
    color: $primary;
    text-decoration: none;
  }
  .exportButton:hover {
    color: $highlightColor;
  }
  .parametersMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px 10px;
    border-bottom: 1px solid white;
    .parameters {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      margin-right: 50px;
    }
    .dropDown {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.growthTable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header {
  width: 100%;
  text-align: center;
  font-size: $header;
  text-transform: uppercase;
}

.table {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 400px;
  max-width: 700px;
  border: 1px solid white;
  border-radius: $borderRadius;
  padding: 20px 10px;
  margin: 30px;
}

.tableHead {
  width: 100%;
  text-align: center;
  font-size: $header;
  text-decoration: underline;
}

.rowLabels {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
  width: 100%;
  font-style: italic;
  border-bottom: 1px dotted white;
  div {
    padding: 5px 10px;
    flex: 1;
  }
  div:nth-child(1) {
    flex: 3;
  }
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  div {
    padding: 5px 10px;
    flex: 1;
    text-align: right;
  }
  div:first-child {
    text-align: left;
  }
  div:nth-child(1) {
    flex: 3;
  }
}
.tableRow:nth-child(odd) {
  background-color: lighten($backgroundLight, 1%);
}
.tableRow:nth-child(even) {
  background-color: darken($backgroundLight, 1%);
}
.averageRow {
  border-top: 1px solid $gray;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  div {
    padding: 5px 10px;
    flex: 1;
    text-align: right;
  }
  div:first-child {
    text-align: left;
  }
  div:nth-child(1) {
    flex: 3;
  }
}

@media print {
  .tableRow {
    border: 1px solid gray;
    border-collapse: collapse;
  }
  .growthReport {
    .reportButtons {
      display: none;
    }
  }
  .averageRow {
    border: none;
  }
}