@import "../../variables.scss";

.button {
  box-sizing: border-box;
  background: $buttonColorA1;
  color: $buttonColorA2;
  border: 1px solid $buttonColorA2;
  border-radius: $borderRadius;
  line-height: 1.4em;
  font-weight: $normal;
  outline: none;
  text-decoration: none;
  font-size: $extraSmall;
  text-transform: lowercase;
  padding: 3px 5px;
  // background: lighten($buttonColorA2, 80%);
  
  &:hover {
    cursor: pointer;
    // font-weight: $bold;
    background: $buttonColorA2;
    color: $buttonColorA1;
    border-color:  $buttonColorA1;
  }

  &:disabled {
    cursor: default;
    background: $grayDark;
    color: $gray;
    border-width: 1px;
  }
}

.button.cta {
  background: $buttonColorA2;
  color: $buttonColorA1;
  border-color:  $buttonColorA1;

  &:hover {
    background: $buttonColorA1;
    color: $buttonColorA2;
    border-color:  $buttonColorA2;
  }
  &:disabled {
    cursor: default;
    background: $grayDark;
    color: $gray;
    border-width: 1px;
    border-color: $gray;
  }
}

.button.transparent {
  background: none;
  // background: rgba(255,255,255,0.25);
  color: white;
  border-color: white;  
  &:hover {
    // background: none;
    background: rgba(255,255,255,0.25);
  }
}

button.small {
  font-size: $extraSmall;
  outline: none;
  padding: 3px 5px;
  // background: lighten($buttonColorA2, 80%);

  text-transform: lowercase;
}


button.link {
  background: none;
  border:none;
  color: $buttonColorA2;
 
  &:hover {
    color:$highlightColor;
  }
}

