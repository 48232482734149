@import '../../../variables.scss';

.percentileChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  .chartContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .score {
    display: flex;
    align-items: center;
    position: absolute;
    font-weight: bold;
    font-size: 2rem;
  }
  .chartTitle {
    box-sizing: border-box;
    display: flex;
    font-size: $subHeader;
  }
}

:export { completed: $primary; }
:export { remaining: white; }
