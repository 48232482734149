@import '../../variables.scss';

.dashboardCardPracticeTestHistory {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 10px;

  .title {
    font-size: $subHeader;
  }
  .footnote {
    font-size: $tinyText;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;  
  }

  .chartContainer {
    display: flex;
    flex-direction: column;
    margin-right:30px;
  }

  .subTitle {
    margin-top: 5px;
    font-size: $standard;
    font-weight: $bold;
    margin: 2px;
    color: $primary;
    text-transform: uppercase;
  }
  
}