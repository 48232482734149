@import '../../variables.scss';

.dashboardCardNextPracticeTest {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  box-shadow: $box-shadow;

  background: white;
  margin: 5px;
  padding: 20px 50px;

  .subTitle {
    margin-top: 5px;
    font-size: $standard;
    font-weight: $bold;
    margin: 2px;
    color: $primary;
    text-transform: uppercase;
  }
  .title {
    font-size: $subHeader;
    margin: 2px;
  }
  .footnote {
    font-size: $tinyText;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .calendarContainer {
    text-align: center;
  }

  .metric {
    .number {
      font-size: $subHeader;
      font-weight: normal;
    }
    .text {
      font-size: $small;
    }
  }
  
  .separator {
    border-bottom: 1px solid $primary;
  }

}
.calendar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  width: 150px; height: 150px; 
  border-radius: $borderRadius;
  // box-shadow: $box-shadow;
  .month {
    display: flex;
    flex-direction: column;
    
    box-sizing: border-box;
    font-size: 24px;
    background: $primary;
    color: white;
    width: 100%;
    padding: 10px 2px;
    font-weight: $light;
  }
  .date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 10px;
    font-size: 64px;
    color: $grayDark;
    font-weight: bold;
    width: 100%;
    border: 1px solid $gray;
    // box-shadow: $box-shadow;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;

  }
  .day {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 14px;
    color: $grayExtraDark;
    width: 100%;
    font-weight: $normal;
    text-transform: uppercase;
  }
}