@import '../../variables.scss';

.successMessage {
    min-height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: white;
    border: 1px solid $primary;
    color: $primary;
    border-radius: $borderRadius;
}
.title {
    color: $primary;
    font-weight: bold; 
}
.message {
    display: flex;
    flex-direction: column;
}
.icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
